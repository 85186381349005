(function($, generic, site) {

"use strict";

site.caLangSelect = site.caLangSelect || {};

site.caLangSelect = {
  conf: {
    popover_display_modal_mask : 1,
    display_po_immediately     : 1,
    homepage                   : 1,
    lang_pref                  : 48,
    popover_filename           : '/promotions/includes/popovers/interstitial.tmpl',
    popover_style_left         : '0px',
    callback_name              : 'undefined',
    pop_up_count_cookie        : 'ca_lang_select_count',
    set_popover_cookie_only    : 0,
    cookie_duration            : '30'
  },

  initConf: function() {
    if (this.conf.popover_filename) {
      this.process();
    }
  },

  init: function() {
    try {
      this.initConf();
    }
    catch(err) {
      // console.log(err);
    }
  },

  process: function () {
    var siteWidePopOverPendingOrVisible = true;
    var self = this,
        conf = this.conf;
    
    
    if (generic.cookie(conf.pop_up_count_cookie))
      return;
    
    var url = conf.popover_filename;
    var includeBackgroundFlag = conf.popover_display_modal_mask;

    $.ajax(url, {
      dataType: 'html',
      success: function(html) {
        if (! /\S/.test(html)) {
          self.setCookie();
          return;
        }
        
        var popup_timer = '';

        var popover_div = $('<div id="popover_container" ></div>').html(html).get(0);
        var img_width   = $('img', popover_div).attr('width') || $('table', popover_div).attr('data-width');
        var img_height  = $('img', popover_div).attr('height') || $('table', popover_div).attr('data-height');
                              
        var left = self.get_left_position(popover_div);
                              
        // If the offers shade has not been seen, dont show popover for
        //  5 seconds until after the shade automatically rolls back up,
        //  set in globalnav.js, unless the display_immediately flag is
        //  defined in the config file
        var time_to_wait = (conf.display_po_immediately) ? 0 : conf.homepage ? 30000
              : (!generic.cookie('viewed_offers_shade')) ? 2500
              : 2500
              ;
        window.setTimeout(function() {
          generic.overlay.launch({
            content: popover_div,
            includeBackground: includeBackgroundFlag,
            fixed: 'true',
            appearDuration: 1.0,
            cssStyle: {
              overflow: 'visible ',
              border: 'none',
              padding: 0,
              backgroundColor: '#000',
              height: img_height,
              width: '550px',
              // left: conf.popover_style_left,
              position: 'fixed',
              className: 'overlay--interstitial',
              trapFocus: false,
              onComplete: function() {
                $('#cboxClose').on('click', function(){
                  site.caCookieLegal.process();                
                });
              }
            }
          });
  
          var callback = conf.callback_name;
          if (typeof(callback) === 'function') {
            callback();
          }

          self.setCookie();
        }, time_to_wait);
      }
    });
  },

  setCookie : function() {
    var conf = this.conf;
    
    var expDate = new Date();
    expDate.setDate(expDate.getDate() + conf.cookie_duration);
    var expDateString = expDate.toGMTString();

    generic.cookie(conf.pop_up_count_cookie, '1',  { path: '/', expires: expDateString });
  },

  close_sitewide_popover: function() {
    siteWidePopOverPendingOrVisible = false;
    generic.overlay.hide();
  },
  
  // Need this width for centering popup
  getLargestWidth: function(POContainer) {
    var blocks = $('div', POContainer);
    var largestWidth = 0;

    blocks.each(function(n) {
      var nWidth = $(this).css('width');
      if (nWidth != null) {
        nWidth = Number(nWidth.sub('px', ''));
        largestWidth = (nWidth > largestWidth) ? nWidth : largestWidth;
      }
    });

    return largestWidth;
  },

  get_left_position: function (POContainer) {
    var width = this.getLargestWidth(POContainer);
    var windowWidth = $.browser.msie ? document.documentElement.clientWidth : window.innerWidth;

    return (windowWidth / 2) - (width / 2);
  }
};


// Use standard Drupal behaviors to initialize things
Drupal.behaviors.bumbleCAInterstitial = {
  attach: function (context, settings) {
    if (this.attached || !Drupal.settings.enable_interstitial) {
      return;
    }
    this.attached = true;

    site.caLangSelect.init();
    $('body', context).addClass('locate-interstitial-popup');
  },

  attached: false
};
  
})(jQuery, window.generic || {}, window.site || {});
